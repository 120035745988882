@import './variables';

.login_new_page {
  display: flex;
  height: 100vh;

  .login_left {
    width: 47%;
    display: flex;
    height: 100vh;
    justify-content: center;
    overflow: hidden;

    .login_left_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      overflow: auto;
      padding: 40px;
      width: 100%;
      background-color: #fff;

      @media (max-width: 1300px) {
        padding: 25px 40px;
      }

      .login_center {
        max-width: 460px;
        max-height: 100%;
      }
    }

    .login_logo {
      margin-bottom: 48px;

      @media (max-width: 1400px) {
        margin-bottom: 30px;
      }

      @media (max-width: 1200px) {
        margin-bottom: 18px;
      }
    }

    .welcome_head {
      color: #16181D;
      font-size: 32px;
      font-weight: 400;
      line-height: 36px;
      margin-bottom: 16px;

      @media (max-width: 1400px) {
        margin-bottom: 10px;
        font-size: 30px;
      }

      @media (max-width: 1200px) {
        font-size: 25px;
        margin-bottom: 6px;
      }
    }

    .welcome_text {
      color: #505560;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .login_sep {
      background-color: #E4E5E7;
      width: 100%;
      height: 1px;
      margin: 40px 0;

      @media (max-width: 1400px) {
        margin: 28px 0;
      }

      @media (max-width: 1200px) {
        margin: 20px 0;
      }
    }

    .login_form {
      .email_field {
        .ant-form-item {
          margin-bottom: 32px;

          @media (max-width: 1400px) {
            margin-bottom: 24px;
          }

          @media (max-width: 1200px) {
            margin-bottom: 20px;
          }
        }
      }

      .ant-form-item {
        @media (max-width: 1200px) {
          margin-bottom: 20px;
        }

        .formControl {
          border: 1px solid #E4E5E7;
          border-radius: 8px;
          padding: 8px 12px;
          height: 36px;

          &.ant-input-password {
            input {
              background: transparent;

              &::-webkit-autofill,
              &::-webkit-autofill:focus,
              &::-webkit-autofill:hover,
              &::-webkit-autofill {
                box-shadow: inset 0 0 0 100px #fff;
                -webkit-box-shadow: 0 0 0 100px #fff inset;
              }
            }
          }
        }
      }

      .ant-form-item-label {
        justify-content: flex-start;
      }

      .login_btn {
        width: 100%;
        margin-top: 40px;
        background-color: $blue;
        border-radius: 44px;
        padding: 8px 16px;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        height: 36px;
        margin-bottom: 20px;

        @media (max-width: 1400px) {
          margin-top: 20px;
        }

        @media (max-width: 1200px) {
          margin-top: 14px;
        }

        &:hover {
          background-color: #264DC3;
        }
      }
    }

    .forgot_link {
      color: #16181D;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      padding: 6px;
    }
  }

  .login_right {
    width: 53%;
    background-color: #16181D;
    height: 100vh;
    overflow: hidden;

    .login_right_wrapper {
      padding: 120px 0 0 100px;
      height: 100vh;
      width: 100%;
      display: flex;
      justify-content: flex-start;

      @media (max-width: 1400px) {
        padding: 100px 0 0 90px;
      }

      @media (max-width: 1200px) {
        padding: 80px 0 0 60px;
      }

      img {
        margin-bottom: -3%;
        display: block;
      }
    }
  }
}

@media (max-width: 992px) {
  .login_new_page {
    .login_left {
      width: 100%;
    }

    .login_right {
      display: none;
    }
  }
}
