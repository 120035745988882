@import './variables';

.room_sidebar {
  &.padding_0 {
    .ant-drawer-body {
      padding: 0;
    }

    .top_drawer_header {
      margin: 0 !important;
    }
  }

  .top_drawer_header {
    border-bottom: 0;

    &.border_bottom {
      border-bottom: 1px solid #E4E5E7;
      margin-bottom: 24px;
    }
 }

  .individual_chip {
    padding: 2px 8px;
    margin-right: 8px;
    color: #16181D;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
}

.right_drawer {
  display: flex;
  align-items: center;
}

.drawer_date {
  display: flex;
  align-items: center;
  background-color: #F7F8F9;
  padding: 4px 8px 4px 4px;
  border-radius: 6px;
  color: #16181D;
  font-size: 12px;
  font-weight: 400;
  margin-right: 5px;

  svg {
    width: 16px;
    margin-right: 4px;
  }

  span.data_for {
    color: #505560;
    margin-right: 4px;
  }
}

.edge_card {
  .room_type {
    color: #505560;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
  }

  h4 {
    color: #16181D !important;
    font-weight: 500 !important;
    margin-right: 7px !important;
  }

  .room_number_status {
    display: flex;
    align-items: center;
  }
}

.white_circle_btn {
  background-color: #fff;
  border: 1px solid #E4E5E7 !important;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  padding: 0;
}

.cleaning_guest_top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  .cleaning_time {
    color: #16181D;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &.not_specified {
      color: #9CA0AA;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.cleaning_guest_time {
  display: flex;
  flex-wrap: wrap;

  .cleaning_checkin_full {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .cleaning_guest_full {
    width: 100%;
    border-bottom: 1px solid #F2F3F7;
    margin-bottom: 24px;
  }

  .cleaning_guest_half {
    border-bottom: 1px solid #F2F3F7;
    width: calc(50% - 12px);
  }

  .cleaning_label {
    color: #9CA0AA;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    width: 100%;
    margin-bottom: 8px;
  }

  .cleaning_data {
    color: #16181D;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-bottom: 12px;
  }
}

.cleaning_tab_sidebar {
  .cleaning_label {
    color: #9CA0AA;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .cleaning_two_rows {
    display: flex;
    flex-wrap: wrap;
  }

  .cleaning_full {
    margin-bottom: 24px;
    width: 100%;
    position: relative;
  }

  .rating_edit_drop {
    position: absolute;
    right: 0;
    top: 0;
  }

  .cleaning_half {
    margin-bottom: 24px;
    width: 50%;
    padding-right: 16px;
  }

  .ant-card-meta-avatar {
    padding-right: 8px;

    .ant-avatar {
      width: 28px;
      height: 28px;
    }
  }

  .ant-card-meta {
    margin: 0;
  }

  .cleaning_time {
    color: #16181D;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .ant-card-meta-title {
    color: #16181D;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 2px !important;
  }

  .ant-card-meta-description {
    color: #505560;
    font-size: 14x;
    font-weight: 400;
    line-height: 20px;
  }

  .cleaning_desc {
    color: #16181D;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 2px !important;
  }
}

.sidebar_menu_options {
  width: 260px;

  &.ant-dropdown-menu {
    .ant-dropdown-menu-item {
      .ant-btn {
        padding: 8px 20px;
        height: 36px;
      }
    }
  }

  .options_button {
    width: 100%;
  }

  .sidebar_menu_detail {
    display: flex;
    align-items: center;

    .ant-image {
      display: block;
      margin-right: 8px;
    }

    .sidebar_info {
      color: #16181D;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.status_change_form {
  padding-bottom: 54px;
  margin-top: 24px;
}

.room_status_card {
  border-radius: 16px;
  border: 0;

  &.disabled_card {
    background-color: #FBFBFC;

    h5, h2 {
      color: #E4E5E7;
    }

    .status_tags {
      .ant-tag {
        color: #E4E5E7;
      }
    }
  }

  .ant-card-body {
    padding: 12px;
  }

  h5 {
    color: #F7F8F9;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 4px;
  }

  h2 {
    color: #fff;
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 12px;
  }

  .status_tags {
    display: flex;
    white-space: nowrap;

    .ant-tag {
      background-color: #ffffff24;
      width: auto;
      padding: 4px 12px;
      height: auto;
      margin-right: 8px;
    }
  }

  .status_icon {
    margin: -8px 0 -8px 0;
    display: flex;
    align-items: center;
    z-index: 9999;
    position: relative;
    justify-content: center;
  }


  &.defaultCard {
    background-color: rgb(185,187,189);
    color: rgb(185,187,189);
  }
  &.greenCard {
    background-color: rgb(107,184,112);
    color: rgb(107,184,112);
  }
  &.blueCard {
    background-color: rgb(94,149,2312);
    color: rgb(94,149,231);
  }
  &.orangeCard {
    background-color: rgb(250,141,105);
    color: rgb(250,141,105);
  }
  &.pelorousCard {
    background: #47AABF;
    color: #47AABF;
  }
  &.newBlueCard {
    background: #8578D7;
    color: #8578D7;
  }
  &.margueriteCard {
    background: rgba(231, 169, 46, 1);
    color: #E7C85D;
  }
}

.linen_change_mark {
  background-color: #FBFBFC;
  border: 0;
  border-radius: 12px;

  .ant-card-body {
    padding: 16px;
  }

  h3 {
    color: #16181D;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0;
  }

  .linen_card {
    padding: 8px 0;
    .text_switch {
      color: #16181D;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 0;
    }

    .ant-switch {
      height: 23px;
      background-color: #E4E5E7;
      border: 0;

      &.ant-switch-checked {
        background-color: #0B9F57;
      }

      .ant-switch-handle {
        width: 18px;
        height: 18px;

        &::before {
          background-color: #fff !important;
        }
      }
    }
  }
}

.footer_cleaning {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 520px;
  border-top: 1px solid #E4E5E7;
  background: #fff;
}

.cleaning_tab_sidebar {
  padding-bottom: 146px;
}

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1,
}

.cleaning_guest_row {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 13px;
  justify-content: space-between;

  .cleaning_guest_right {
    margin-top: -6px;
  }
}

.time_req_form {
  position: relative;

  .time_input {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-picker-suffix {
      display: none;
    }

    .ant-picker-clear {
      right: 30px;
    }
  }

  .time_save_btn {
    position: absolute;
    right: 0;
    top: 2px;

    button {
      background: transparent;
      padding: 11px 12px 10px 10px;
      color: #16181D;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
    }
  }
}

.status_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -6px 0;
  position: relative;
  z-index: 9;
}

.footer_status_change {
  position: fixed;
  width: 520px;
  right: 0;
  bottom: 0;
  z-index: 9;

  .status_div {
    border-top: 1px solid #F7F8F9;
    padding: 8px 32px 20px;
    background-color: #F7F8F9;

    p {
      &.status_title {
        color: #505560;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 8px;
      }
    }

    .status_btn_wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .btn_div {
      button {
        width: 220px;
        height: 48px;
        color: #16181D;
        border: 1px solid #E4E5E7 !important;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;

        &.ant-btn > .anticon + span {
          font-size: 14px !important;
          font-weight: 400;
          line-height: 20px;
        }

        &.dirty_btn {
          &:hover {
            background-color: #D95B33;
            border: 1px solid #D95B33 !important;
            color: #fff;

            > .anticon {
              path {
                fill: #fff
              }
              circle {
                fill: #D95B33
              }
            }
          }
        }

        &.clean_btn {
          &:hover {
            background-color: #392EC2;
            border: 1px solid #392EC2 !important;
            color: #fff;

            > .anticon {
              path {
                fill: #fff
              }
              circle {
                fill: #392EC2
              }
            }
          }
        }
      }
    }
  }
  .status_change_btn {
    border-top: 1px solid #F7F8F9;
    padding: 24px 32px;
    background-color: #fff;
  }
}

.cleaning_in_progress {
  width: 100%;
  background: #fff;
  margin-bottom: 24px;

  .ant-card {
    border: 1px solid #F4F4F9;
  }

  .ant-card-body {
    padding: 16px;
    display: flex;
    align-items: center;
  }

  .cleaning_icon {
    background-color: #392EC2;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    img {
      display: block;
    }
  }

  .cleaning_card_left {
    display: flex;
    align-items: center;
    span {
      color: #16181D;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .stop_cleaning_btn {
    margin-left: auto;

    button {
      padding: 8px 12px;
      display: flex;
      align-items: center;
      background-color: transparent;
      border: 1px solid #E4E5E7 !important;
      border-radius: 44px;
      color: #16181D;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;

      .ant-image {
        margin-right: 8px;
      }
    }
  }
}

.guest_meta {
  margin-bottom: 24px;
  .ant-card-meta-avatar {
    padding-right: 6px;
  }
  .ant-card-meta-detail {
    display: flex;
    align-items: center;
  }
}

.guest_note {
  margin-bottom: 24px;
  background-color: #FFF8EB;
  border: 0;

  .ant-card-body {
    padding: 12px;
  }

  .ant-card-meta {
    margin: 0;
  }

  .ant-card-meta-title {
    margin-top: 1px;
    color: #16181D;
    font-size: 14px;
    line-height: 20px;
  }

  .ant-card-meta-avatar {
    padding-right: 8px;
  }

  .ant-card-meta-description {
    color: #16181D;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

}

.not_reserved_card {
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .not_res_text {
    color: #686D79;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 12px;
  }
}

.pinned_note {
  margin-bottom: 24px;

  .ant-card-body {
    background-color: #F0EFFB80;
    border: 0;
    padding: 16px;
  }

  .pinned_note_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #E7A92E26;

    .pinnote_text {
      color: #16181D;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .pin_head_right {
    display: flex;
    align-items: center;
  }

  .pin_date {
    color: #505560;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-right: 4px;
  }

  .pin_btns {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: #F0EFFB;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;

    &.pin_more {
      button {
        color: #782ec2;
      }
    }
  }

  .pin_paragraph {
    color: #16181D;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.rate_btns {
  background: #fff;
  border-top: 1px solid #E4E5E7;
  padding: 24px 32px;
}

.room_sidebar .preloader {
  position: absolute;
  top: 0;
  right: 0;
  width: 520px;
  height: 100%;
  background-color: #ffffff60;
}

.cleaning_sidebar {
  width: 38px;
  height: 24px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  border-radius: 24px;

  img {
    display: block;
  }
}

.no_photo_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: #F7F8F9;
  border-radius: 12px;
  width: 456px;
  height: 200px;

  .ant-image {
    margin-bottom: 16px;
  }

  p {
    width: 100%;
    margin-bottom: 0;
    max-width: 203px;
    color: #505560;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
  }
}

.pinned_note_content {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  background: #fff;
  z-index: 9;
  padding: 12px 16px 12px 12px;

  .left_pinned {
    position: relative;
    padding-left: 10px;

    h4 {
      color: rgba(11, 159, 87, 1);
      line-height: 16px;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 2px;
    }

    h5 {
      color: rgba(22, 24, 29, 1);
      line-height: 16px;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 0;
    }

    &::before {
      content: '';
      background-color: rgba(11, 159, 87, 1);
      border-radius: 4px;
      width: 2px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .right_pinned {
    button {
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
}

.ant-spin {
  svg {
    path {
      fill: $blue;
    }
  }
}
