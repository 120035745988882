@import './variables';

.settings_section {
  width: 100%;
  padding: 40px;
  min-height: 100vh;

  .section_header {
    box-shadow: none;
  }
}

.settings_card {
  width: 100%;

  > .ant-card-body {
    padding: 0;
  }

  .ant-collapse {
    > .ant-collapse-item {
      > .ant-collapse-header {
        flex-direction: row-reverse;
        align-items: center;
        padding-left: 0;
        padding-right: 0;

        .ant-collapse-expand-icon {
          padding-top: 4px;
          margin: 0 0 0 10px;
        }

        .ant-collapse-arrow {
          transition: 0.5s;
        }
      }

      &.ant-collapse-item-active {
        .ant-collapse-arrow {
          transform: rotate(180deg);
        }
      }
    }
  }

  .card_req_wrapper {
    .ant-tabs-tabpane {
      padding-left: 0 !important;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
      flex: none;
    }

    .ant-tabs-extra-content {
      border-top: 1px solid #e4e5e7;
      margin-top: 16px;
      padding-top: 16px;

      .ant-btn {
        padding: 8px;
        background: transparent;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 8px;

        &.black {
          color: #16181d;
        }
        &.red {
          color: #e04369;
        }
      }
    }
  }
}

.drag_header {
  .anticon-holder {
    svg {
      height: 18px;
      width: 18px;
    }
  }
  .drag-item-heading {
    width: 370px;
    font-size: 14px;
    color: #1b1c1d;
    line-height: 21px;
    letter-spacing: 0.003em;
    margin-left: 12px;
  }
}

.upper-table {
  .ant-table-container table > .ant-table-tbody > tr td:last-child {
    border-bottom: none;
    padding: 7px 0;
  }
}

.row-dragging {
  background: #fafafa;
}

.row-dragging td {
  padding: 7px 0;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.inner-table {
  .innerTable {
    .ant-table {
      width: 495px;
      padding-left: 16px;
      margin-left: 0;
    }
  }
}

.card_req_wrapper {
  .ant-tabs-nav {
    width: 200px;

    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-tab {
      padding: 0;
      margin: 0 0 8px 0 !important;
      width: 170px;

      + .ant-tabs-tab {
        margin-top: 0;
      }

      .ant-tabs-tab-btn {
        padding: 6px 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #686d79;
      }

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          background-color: #f2fcf7;
          color: #0b9f57;
          width: 100%;
          text-align: left;
          display: block;
          text-shadow: none;
          border-radius: 6px;
        }
      }

      &:hover {
        color: $blue;
      }
    }

    h5 {
      margin-bottom: 0;
      padding: 16px 24px;
      color: $blue;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      position: relative;

      &::after {
        content: '';
        background: $blue;
        border-radius: 5px 0px 0px 5px;
        width: 4px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .ant-tabs-content-holder {
    border-left: 0;
    padding: 0 0 0 16px;

    .add_section .ant-form-item input.ant-input.formControl {
      padding: 0 !important;
    }

    .ant-tabs-content-left {
      border-radius: 20px;
      border: 1px solid #e4e5e7;


      h4 {
        &.ant-typography {
          font-weight: 500;
          margin-bottom: 8px;
        }
      }

      .setting_detail_form {
        .location_photo {
          margin-bottom: 40px;
        }
        .group_inputs {
          margin-bottom: 40px;

          .ant-form-item {
            margin-bottom: 16px;
          }
        }
        .upload_loc_image,
        .ant-form-item {
          margin-bottom: 40px;
        }

        .formControl {
          border: 1px solid #e4e5e7 !important;
        }
      }

      .loc_detail_form {
        padding: 0;
        min-height: inherit;
        align-items: flex-start;

        .loc_wrapper {
          padding: 0;
          min-height: auto;
          padding-bottom: 20px;
        }

        .ant-select-selector {
          border: 1px solid #dbdddf;
          border-radius: 8px;
          height: 36px;

          .ant-select-selection-search-input {
            height: 36px;
            padding: 8px 12px !important;
          }
          .ant-select-selection-placeholder {
            line-height: 34px;
          }
        }

        .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background-color: white;
        }

        .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
          color: black;
        }

        .formControl {
          border: 1px solid #dbdddf;
          border-radius: 8px;
          padding: 8px 12px !important;
          height: 36px;

          .ant-input-number-input {
            height: auto;
            padding: 0;
          }
        }

        .detail_title {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;

          span {
            display: block;
            width: 100%;
            font-size: 12px;
            line-height: 16px;
            margin-top: 8px;
          }
        }

        .btn_details {
          border-top: 1px solid #e4e5e7;
          flex: 0 0 100%;
          max-width: 100%;
          width: 100%;
          padding: 24px 32px;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: #fff;
          z-index: 9;
          border-radius: 0 0 20px 20px;
        }
      }
    }
  }

  .common_wrapper {
    padding: 0;
  }

  .location_btm {
    height: calc(100vh - 280px);
  }

  .ant-tabs-content {
    > .ant-tabs-tabpane {
      padding-left: 0;
    }
  }

  h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.003em;
    color: $blackColor;
    margin-bottom: 27px;
  }

  .apply-btn {
    border-radius: 8px;
    padding: 13px 20px !important;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 125%;
    letter-spacing: 0.006em;
  }

  .req_collapse {
    width: 480px;
    margin-bottom: 32px;
  }

  .ant-collapse {
    border-bottom: 1px solid #dbdddf;
    border-radius: 0;

    &:last-child {
      border-bottom: 0;
    }

    .inner-table,
    .drag-upper-header {
      .right_btn {
        opacity: 0;
        height: 35px;
      }
    }

    tr {
      .drag-upper-header {
        &:hover {
          .right_btn {
            opacity: 1;
          }
        }

        &:hover {
          border-radius: 6px;
          &:hover {
            background: rgba(185, 187, 189, 0.14) !important;
          }
        }
      }
    }

    .innerTable {
      tr {
        &:hover {
          .right_btn {
            opacity: 1;
          }
        }

        td {
          &:hover {
            border-radius: 6px;
            &:hover {
              background: rgba(185, 187, 189, 0.14) !important;
            }
          }
        }
      }
    }

    .ant-collapse-header {
      padding: 16px 0;
      .ant-collapse-header-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $blackColor;
        letter-spacing: 0.003em;
      }
      .ant-collapse-expand-icon {
        padding-top: 0 !important;
      }
    }

    .ant-collapse-content-box {
      padding: 0 !important;

      .row-card {
        margin-bottom: 12px;

        .setting-btn {
          padding: 9px 12px;
          background: rgba(185, 187, 189, 0.22);
          border-radius: 6px;
          font-size: 13px;
          line-height: 16px;
          color: #7f8385;
          font-weight: 500;
          height: 34px;
          display: flex;

          .plus-icon {
            color: #7f8385;
            font-size: 15px !important;

            + span {
              font-size: 13px !important ;
              margin-left: 5px;
            }
          }
        }
      }
    }

    .upper-table {
      margin-bottom: 12px;

      .ant-table-tbody {
        tr {
          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }

    .empty-text {
      border: 1px dashed #dbdddf;
      border-radius: 10px;
      width: 100%;
      height: 100px;
      padding: 23px 24px;

      .empty-col {
        display: inline-flex;
        align-items: center;

        .text {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #7f8385;
          margin-left: 16px;
        }
      }
    }
  }
}

.setting_add_category {
  .closeBtn {
    margin-right: -22px !important;
  }
  .ant-form-item {
    padding-left: 0px !important;
    margin-bottom: 4px;
  }
  .ant-form-item-control-input-content {
    .formControl {
      .ant-picker-input {
        .ant-picker-suffix {
          display: none;
        }
        input {
          margin: 9px 0;
        }
      }
    }
  }
  .col-time {
    display: flex;
    .anticon-info-circle {
      position: inherit;
      color: #7f8385;
    }
  }
  .popup_select {
    .ant-select-show-search {
      .ant-select-selection-placeholder {
        line-height: 50px;
      }
    }
  }
  .col-time {
    .request-due-time .ant-form-item-control {
      width: 206px;
    }
  }
  .request-due-time {
    padding-right: 40px;
  }
}

.ant-switch {
  background: white;
  border: 1px solid #dbdddf;
  .ant-switch-handle {
    background: #dbdddf;
  }
}

.ant-switch-checked {
  background: $blue;

  .ant-switch-handle {
    &:before {
      background: #fff;
    }
  }
}

.ant-switch-handle {
  width: 20px;
  height: 20px;
  top: 2px;
  left: 3px;
  background: #ffffff;
  border-radius: 51px;

  &:before {
    background: #dbdddf;
    box-shadow: none;
  }
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 20px - 3px);
}

.drag-upper-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1px 8px;

  .drag-icon {
    width: 18px;
  }
  .drag-card-item {
    background: none;
    width: 358px;
    color: #5e95e7;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin-left: 8px;
  }

  .right_btn {
    margin-left: auto;
    width: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.drag-inner-header {
  width: 480px;
  .drag-icon {
    width: 18px;
    margin-right: 8px;
    margin-left: 8px;
  }
  .drag-card-item {
    width: 354px;
    font-size: 14px;
    color: #1b1c1d;
    line-height: 21px;
    letter-spacing: 0.003em;
    background: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    display: block;

    .duetime {
      font-size: 12px;
      line-height: 17px;
      font-weight: 400;
      letter-spacing: 0.003em;
      color: #7f8385;
    }
  }
}

.popup_select {
  .ant-select-selection-search {
    input {
      margin-top: 10px !important;
    }
  }
  .ant-select-selection-item {
    padding-top: 10px !important;
  }
}

.delivery_card {
  width: 540px;

  .ant-card {
    margin-bottom: 16px;
  }

  .ant-card-head {
    min-height: auto;
    padding: 0;
    border: 0;
    margin: 0;
    position: absolute;
    right: 12px;
    top: 20px;

    .ant-card-extra {
      padding: 0;
    }

    .menu_icon {
      width: 34px;
      height: 34px;
      padding: 0;
      border-radius: 4px;
      background: transparent;

      &:hover {
        background: rgba(185, 187, 189, 0.22);
      }
    }
  }

  .ant-card-meta {
    margin: 0;

    .ant-card-meta-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-card-meta-detail {
    .ant-card-meta-title {
      margin: 0 0 2px 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      letter-spacing: 0.003em;
      color: #1b1c1d;
    }
    .ant-card-meta-description {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.003em;
      color: #7f8385;
    }
  }

  .ant-card-bordered {
    border: 1px solid #dbdddf;
    border-radius: 12px;

    .ant-card-body {
      padding: 16px;
    }
  }

  .empty_no_delivery {
    padding: 32px 0 0 0;

    .ant-empty-image {
      margin-bottom: 16px;
    }

    .ant-empty-description {
      .topText {
        width: 100%;
        display: block;
        color: #fa8d69;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 8px;
      }

      .btmText {
        width: 100%;
        display: block;
        color: #7f8385;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }
    }

    .ant-empty-footer {
      margin-top: 16px;

      .btn_no_delivery {
        padding: 11px 0;
        background-color: transparent;
        color: $blue;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}

.singleCheckbox {
  .ant-form-item-control-input {
    min-height: auto;
  }

  .ant-checkbox-wrapper {
    display: inline-flex;
    align-items: flex-start;

    &.ant-checkbox-wrapper-checked {
      &:hover {
        .ant-checkbox-inner {
          background-color: #018545;
          border-color: #018545;
        }
      }

      .ant-checkbox-checked {
        &:after {
          border-color: #018545;
        }
      }
    }

    &:hover {
      .ant-checkbox-inner {
        border: 2px solid #818691;
      }
    }

    .ant-checkbox-inner {
      border: 2px solid #9CA0AA;
    }
  }

  .ant-checkbox {
    height: 20px;
    width: 20px;

    + span {
      padding-left: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.003em;
      color: #1b1c1d;
      padding-top: 2px;
    }
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 4px;

    &:after {
      top: 40%;
      left: 20%;
      width: 6px;
      height: 12px;
    }
  }

  .ant-checkbox-checked {
    &:after {
      width: 20px !important;
      height: 20px !important;
      border: 1px solid $blue;
      border-radius: 4px;
    }

    .ant-checkbox-inner {
      background-color: $blue;
      border-color: $blue !important;

      &:after {
        transform: rotate(45deg) scale(1) translate(-50%, -50%) !important;
      }
    }
  }
}

.hotel_admin_role {
  margin-top: 28px;

  .common_wrapper {
    padding: 0;
  }
}

.delivery_timing {
  display: flex;
  p {
    margin: 0;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    letter-spacing: 0.003em;
    color: #1b1c1d !important;
    text-transform: capitalize;
    white-space: nowrap;
    margin-right: 8px;
    min-width: 30px;
    margin-bottom: 0 !important;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      margin-right: 8px;
      background: #f5f5f5;
      border-radius: 4px;
      padding: 2px 6px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.003em;
      color: #1b1c1d;
      margin-bottom: 8px;
      width: 74px;
      text-align: center;
    }
  }
}

.delivery_body {
  margin-top: 16px;
  border-top: 1px solid #dbdddf;
  padding-top: 16px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 50px;
  width: calc(100% - 50px);

  .delivery_col {
    width: 50%;
  }

  h4 {
    color: #5e95e7;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .pickup {
    h4 {
      color: #fa8d69;
    }
  }
}

.location_photo {
  display: flex;
  margin-bottom: 48px;

  .location_image {
    margin-right: 24px;

    .ant-image {
      img {
        width: 108px;
        height: 108px;
        object-fit: cover;
        border-radius: 12px;
      }
    }
  }
}

.setting_inner_height {
  height: calc(100vh - 140px);
  padding: 32px 32px 0;
  overflow: auto;
  overflow-x: hidden;
  min-height: 600px;
  width: 100%;

  .loc_wrapper {
    padding-bottom: 0 !important;
  }

  &.btn_bottom {
    padding-bottom: 94px;
  }

  .deleviry_accordian {
    > .ant-collapse-item {
      .ant-collapse-header {
        padding-right: 8px !important;
      }

      .ant-collapse-content {
        margin-top: 20px;

        .ant-form-item-label {
          justify-content: flex-start;
        }
      }
    }
  }

  .ant-collapse .delivery_timebox .formControl .ant-picker-input .ant-picker-suffix {
    margin-left: 0 !important;
  }

  .upload_csv_floor .ant-upload.ant-upload-drag {
    height: calc(100vh - 390px);
    border: 1px dashed #fbfbfc;

    &:hover {
      border: 1px dashed #d5d7db;
    }
  }
}

.hotel_creation {
  padding: 32px 0 84px 0 !important;
  overflow: hidden;

  .floors_wrapper,
  .loc_wrapper,
  .add_hotel_floor {
    height: 100%;
  }

  .floow_boxes_room {
    overflow: initial !important;
    height: 100%;
  }

  .floor_scroll_wrapper {
    height: calc(100% - 100px);
    overflow: auto;

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  .top_head_btn {
    padding: 0 32px;
  }

  .add_floor_btn {
    position: sticky;
    left: 32px;
    margin-left: 32px;
  }

  .floor_inline {
    padding: 0 32px 20px;
  }

  .floor_cards {
    padding-left: 32px;
  }
}

.connect_btn {
  margin-right: 8px;
}
