.common_wrapper {
  padding: 25px 30px;
}

.locations_top {
  padding-bottom: 5px;
  h4.ant-typography {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #16181D;
    margin-bottom: 12px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    color: #505560;
  }
  ul {
    padding-left: 20px;

    li {
      list-style-type: decimal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #505560;
    }
  }
}

.location_btm {
  width: 100%;
  height: calc(100vh - 270px);
  overflow: auto;

  .location_wrapper {
    display: inline-flex;
    flex-direction: column;
  }
}

.location_header {
  background: #fff;
  display: inline-flex;
  align-items: center;
  position: sticky; 
  top: 0;
  z-index: 9;
  border-top: 1px solid #F2F3F7;
  border-left: 1px solid #F2F3F7;

  .location_search {
    width: 400px;
    position: sticky;
    left: 0;
    z-index: 9;
    background: #fff;

    +.header_cols {
      border-left: 1px solid #F2F3F7;
    }

    .ant-input-affix-wrapper {
      padding: 16px 24px;
      background: transparent;
      border: 0;

      &:focus {
        box-shadow: none;
      }

      &:not(.ant-input-affix-wrapper-disabled),
      &:not(.ant-input-affix-wrapper-disabled):hover {
        box-shadow: none;
      }
    }

    .ant-input-prefix {
      margin-right: 10px;
    }

    .ant-input {
      color: #686D79;
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
      background-color: transparent;
      
      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        opacity: 1;
        color: #686D79;
      }
      &:-ms-input-placeholder {
        opacity: 1;
        color: #686D79;
      }
      &::-webkit-input-placeholder {
        opacity: 1;
        color: #686D79;
      }
    }
  }
  .header_cols {
    width: 248px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-right: 1px solid #F2F3F7;
    padding: 16px 12px;

    .header_role {
      color: #686D79;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 4px;
    }

    .header_label {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #16181D;
      display: flex;
      align-items: center;
    }
  }
}

.table_wrapper {
  .ant-checkbox-checked {
    &::after {
      border: 0;
    }
  }
  .location_table {
    border: 1px solid #F2F3F7;
    margin-bottom: 40px;
    border-left: 0;

  }

  .table_header {
    color: #505560;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    span {
      display: inline-flex;
      position: sticky;
      left: 0;
      padding: 16px;
      border-left: 1px solid #F2F3F7;
    }
  }
  .table_rows {
    border-top: 1px solid #F2F3F7;
    display: flex;
    background: #FBFBFC;
  }

  .rows_head-sticky-container {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #fff; 
  }

  .rows_head {
    width: 400px;
    padding: 16px;
    border-right: 1px solid #F2F3F7;
    background-color: #fff;
    position: sticky;
    left: 0;
    z-index: 1;
    border-left: 1px solid #F2F3F7;

    .ant-typography {
      color: #505560;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0;

      &.heading {
        margin-bottom: 4px;
        color: #16181D;
        font-weight: 500;
      }
    }
  }

  .rows_cols {
    border-right: 1px solid #F2F3F7;
    width: 248px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    flex-direction: column;
    padding: 20px 8px;


    >span {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
  }
    
    .ant-checkbox-wrapper {
      width: 20px;
      height: 20px;

      .ant-checkbox {
        width: 20px;
        height: 20px;

        .ant-checkbox-inner {
          background-color: transparent;
          border-color: #9CA0AA !important;
          border-radius: 4px;
          margin: 0;
          width: 20px;
          height: 20px;

          &:after {
            position: absolute;
            display: table;
            border: 2px solid #9CA0AA;
            border-top: 0;
            border-left: 0;
            transform: rotate(45deg) scale(1) translate(-40%, -60%);
            opacity: 1;
            transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
            content: " ";
          }
        }

        &.ant-checkbox-disabled {
          opacity: 0.5;
        }

        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            background: #0B9F57;
            border: 0;

            &:after {
              border: 2px solid #fff;
              border-top: 0;
              border-left: 0;
              transform: rotate(45deg) scale(1) translate(-40%, -60%) !important;
            }
          }
          &:after {
            width: 20px !important;
            height: 20px !important;
          }

          &.ant-checkbox-disabled {
            opacity: 0.5;
          }
        }
      }
    }

    .ant-collapse {
      width: 100%;
      .ant-collapse-header {
        justify-content: center;
        padding: 5px 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #16181D;
        flex-flow: row-reverse;

        .ant-collapse-arrow {
          margin-left: 12px;
          margin-right: 0;
        }

        .ant-collapse-header-text {
          flex: inherit;
        }
      }
      .ant-collapse-content {
        > .ant-collapse-content-box {
          padding: 12px 16px;
          background: #FBFBFC;
          border-radius: 6px;
        }
      }

      .collapse_div {
        padding: 8px 0;
        width: 100%;
        text-align: left;

        &:last-child {
          padding-bottom: 0;
        }

        &:first-child {
          padding-top: 0;
        }

        h4 {
          color: #16181D;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 4px;
          white-space: pre-wrap;
          padding-right: 5px;
        }

        p {
          color: #686D79;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 0;
          white-space: pre-wrap;
        }

        .checked_access {
          position: absolute;
          top: 2px;
          right: -6px;

          .ant-checkbox-inner {
            border-radius: 100%;
          }
        }
      }
    }

    .ant-collapse-header-text {
      text-align: center;
    }

    &:last-child {
      border-right: 0;
    }
  }
}

.department_roles {
  .roles_top {
    margin-bottom: 32px;
    h4 {
      color: #16181D;
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 16px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #505560;
      margin-bottom: 0;
    }
  }

  .add_new_department {
    width: 100%;
    margin-bottom: 24px;

    button {
      background: #FBFBFC;
      border-radius: 8px;
      width: 100%;
      height: 40px;
      color: #16181D;
      font-weight: 500;
      line-height: 20px;
      padding: 10px 0;
      
      span {
        font-size: 14px !important;

      }
    }
  }

  .roles_card {
    margin-bottom: 24px;

    .ant-card {
      margin-bottom: 24px;
    }

    .ant-card {
      background: #FFFFFF;
      border: 1px solid #E4E5E7;
      border-radius: 16px;
    }

    h4 {
      color: #0B9F57;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 16px;

      + .department_row {
        position: relative;

        &.only_department {
          &:before,
          &:after {
            display: none;
          }
        }


        &:after {
          content: '';
          background: #E4E5E7;
          position: absolute;
          left: -20px;
          top: 0;
          width: 2px;
          height: 160%;
        }
        &:before {
          content: '';
          background: #E4E5E7;
          position: absolute;
          left: -20px;
          bottom: -60%;
          width: 30px;
          height: 2px;
        }

        + .roles_group_wrapper {
          position: relative;

          .subgroup {
            &:after,
            &:before {
              display: block;
            }

            &::before {
              left: -10px;
              bottom: -123%;
            }
            &::after {
              height: 220%;
              left: -10px;
            }

            &.nosubgroup {
              &:after,
              &:before {
                display: none;
              }
            }
          }

          .subgroup_wrapper {
            h4 {
              margin: 20px 0 15px 20px;
            }
            .subgroup {
              &:after,
              &:before {
                display: none;
              }
            }
            
            .subgroup_wrapper {
              h4 {
                margin: 20px 0 15px 10px;
              }
            }
          }

          &.only_roles {
            &:after,
            &:before {
              display: none;
            }
          }

          &:after {
            content: '';
            background: #E4E5E7;
            position: absolute;
            left: 5px;
            top: 10px;
            width: 2px;
            height: 100%;
          }
          &:before {
            content: '';
            background: #E4E5E7;
            position: absolute;
            left: 6px;
            bottom: -10px;
            width: 32px;
            height: 2px;
          }
        }
      }
    }

    .department_row {
      margin-left: 25px;
      margin-bottom: 24px;
      background: #FBFBFC;
      border-radius: 8px;
      padding: 12px 12px 12px 20px;
      display: flex;
      align-items: center;

      &:hover {
        .department_edit_delete {
          opacity: 1;
          visibility: visible;
        }
      }

      &.subgroup {
        margin-left: 15px;
        .department_name {
          width: calc(100% - 125px);
        }
      }

      .department_name {
        color: #16181D;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        width: calc(100% - 190px);
        display: flex;
        align-items: center;
        justify-content: center;
        
        
      }

      .role_group_btn {
        margin-left: auto;
        button {
          margin-right: 8px;
          background: #FFFFFF;
          border: 1px solid #E4E5E7 !important;
          border-radius: 44px;
          color: #16181D;
          font-size: 12px;
          line-height: 16px;
          padding: 8px 12px;
        }
      }
      .department_edit_delete {
        margin-left: auto;
        opacity: 0;
        visibility: hidden;
      }
    }

    .department_group_wrapper,
    .roles_group_wrapper {
      padding-left: 50px;

      h4 {
        + .department_row {
          &:after,
          &:before {
            display: none;
          }

          &.subgroup {
            &:after,
            &:before {
              display: block;
            }

            &::before {
              left: -10px;
              bottom: -123%;
            }
            &::after {
              height: 220%;
              left: -10px;
            }

            &.nosubgroup {
              &:after,
              &:before {
                display: none;
              }
            }
          }
        }
      }

      .subgroup_wrapper {
        h4 {
          margin: 20px 0 15px -5px;
        }

        .subgroup_wrapper {
          h4 {
            margin: 20px 0 15px 10px;
          }
        }
      }

      .department_row {
        + .role_group_table {
          margin-left: 55px;
        }
      }
    }

    .role_group_table {
      margin-left: 15px;

      &.sub_group_table {
        margin-left: 50px;
      }

      .ant-table {
        .ant-table-container {
          table {
            display: table;
            width: 100%;

            > .ant-table-tbody {
              width: 100%;
              display: block;

              > tr {
                background: #FBFBFC;
                border-radius: 8px;
                margin-bottom: 10px;
                width: 100%;
                display: block;
    
                td {
                  padding: 12px 8px;
                  width: 100%;
                  display: block;
                  border-bottom: 0;

                  &:hover {
                    .drag-inner-header {
                      .right_btn {
                        opacity: 1;
                        visibility: visible;
                      }
                    }
                  }

                  .drag-inner-header {
                    width: 100%;

                    .right_btn {
                      margin-left: auto;
                      opacity: 0;
                      visibility: hidden;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .drag-icon {
      display: none;
    }

    .role_info_wrapper {
      margin-left: 8px;
      .role_top_name {
        color: #16181D;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize;
        margin-bottom: 2px;
      }

      .role_btn_name {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #505560;
        text-transform: capitalize;
      }
    }
  }
}

.add_role_to_group {
  margin-left: 24px;
  margin-top: -24px;
  
  button {
    &.ant-btn {
      &.ant-btn-link {
        padding: 8px 4px;

        svg {
          path {
            fill: #0B9F57
          }
        }

        > .anticon + span {
          margin-left: 8px;
          color: #0B9F57;
          font-weight: 500;
          font-size: 14px !important;
          line-height: 20px;
        }
        
      }
    }
  }
}

.subgroup_wrapper_main {
  margin-left: 50px !important;
}

.main_department {
  position: relative;

  &:before {
    content: "";
    background: #E4E5E7;
    position: absolute;
    left: 8px;
    bottom: -32px;
    width: 30px;
    height: 2px;
  }
  
  &:after {
    content: "";
    background: #E4E5E7;
    position: absolute;
    left: 8px;
    top: 0;
    width: 2px;
    height: calc(100% + 32px);
  }

}

.sub_department {
  position: relative;

  &:after,
  &:before {
    display: none;
  }

  &.without_subgroup {
    &:after,
    &:before {
      display: none;
    }
  }

  

}

.only_department {
  &:before,
  &:after {
    display: none;
  }

}
