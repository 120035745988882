@import './variables';

.user-listing-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .user-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 40px 40px 0 40px;

    @media (max-width: 1400px) {
      padding: 20px 20px 0 20px;
    }

    .header-title {
      display: flex;
      align-items: center;
      margin-bottom: 32px;

      .title {
        font-size: 28px;
        font-weight: 500;
        line-height: 32px;
        color: #16181d;
        margin-bottom: 0;
      }
      .badge {
        padding: 6px;
        min-width: 32px;
        height: 32px;
        background: #f7f8f9;
        border-radius: 6px;
        margin-left: 16px;
        text-align: center;
      }
    }

    .header-search {
      display: flex;
      margin-bottom: 16px;

      .search-bar {
        width: 332px;
        height: 36px;
        padding: 8px 12px;
        border: 1px solid #e4e5e7;
        border-radius: 8px;
        background: #ffffff;
      }

      .ant-input-prefix {
        margin-right: 10px;
        color: #9CA0AA;
      }
    }

    .header-filter {
      width: 100%;
      .section_filter {
        display: flex;
      }
    }
  }

  .user-content {
    padding: 0px 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: 1200px) {
      padding: 0px 20px;
    }

    .table-container {
      box-shadow: none;
      flex-grow: 1;
    }

    .user-avatar {
      margin-right: 10px;
      width: 28px;
      height: 28px;
    }


    .ant-table-container {
      table {
        > .ant-table-thead {
          > tr {
            th {
              &:first-child {
                padding-left: 0;
              }
            }
          }
        }

        > .ant-table-tbody {
          > tr {
            td {
              padding: 14px 8px 12px;

              @media (max-width: 1400px) {
                padding: 14px 4px 12px;
              }
              &:first-child {
                padding-left: 0;
              }
            }
          }
        }

        .ant-checkbox {
          width: 18px;
        }
      }
    }
  }

  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: $blue;
      }
    }
  }

  .ant-checkbox-checked {
    &::after {
      border: 1px solid $blue;
    }

    .ant-checkbox-inner {
      background-color: $blue;
      border-color: $blue;
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      border-color: $blue;
      background-color: $blue;

      &:after {
        background-color: #fff;
        height: 2px;
        border-radius: 8px;
      }
    }
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $blue;
  }

  .ant-pagination-item:hover,
  .ant-pagination-item-active {
    border-color: $blue;
    a {
      color: $blue;
    }
  }
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: $blue;
    color: $blue;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $blue;
  }
}

.create-user-drawer {

  .ant-drawer-content-wrapper {
    width: 520px !important;
  }

  &.ant-drawer {
    .ant-drawer-body {
      padding: 0 32px 84px;
    }
  }
}

.ant-table-tbody,
.ant-table-thead {
  .ant-checkbox-wrapper {
    .ant-checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
    }
  }
}
.ant-table-container table > .ant-table-thead > tr th:last-child {
  text-align: left;
}


.user-status {
  border-radius: 24px;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
}
.user-active {
  background: #0b9f57;
}

.user-notActive {
  background: #9ca0aa;
}

.selected-user-tooltip {
  background: #16181d;
  border-radius: 12px;
  position: fixed;
  height: 64px;
  right: 40px;
  bottom: 20px;
  z-index: 99;
  width: calc(100% - 320px);
  color: white;
  transition: .25s;

  .row {
    padding: 16px;

    .text {
      display: flex;
      align-items: center;

      div {
        background: white;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 4px;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .btns {
      display: flex;
      justify-content: end;
    }
    .cancel-btn {
      background: none;
      color: #fff;
      padding: 8px 16px;
    }

    .delete-btn {
      background: #e04369;
      border-radius: 44px;
      padding: 8px 16px;
      color: #fff;
      margin: 0 16px;
    }

    .send-btn {
      background: #0b9f57;
      border-radius: 44px;
      padding: 8px 16px;
      color: #fff;
    }
  }
}

.custom-scrollbar {
  position: relative;
  height: 300px;
  overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}
.scroll-container {
  position: relative;
  height: calc(100% - 50px);
}




.user-create-form {
  .ant-steps-navigation {
    margin: 0 -32px 0 -32px;
    width: 520px;
    padding-top: 1px;
    border-radius: 0px 4px 4px 0px;
    height: 4px;
    background: #f7f8f9;
    position: relative;
    left: 0;
    top: 0;
    z-index: 0;

    .ant-steps-item {
      &:before {
        background-color: #0b9f57;
      }
      &:after {
        display: none;
      }
    }

    .ant-steps-item-active, .ant-steps-item-finish {
      background: #0b9f57;
    }

    .ant-steps-item-container {
      display: none;
    }
  }




    .step-four {
      .account-ukg-form {
        padding: 12px 16px 16px !important;
        background: #f7f8f9;
        border-radius: 12px;
        margin-top: 32px;

        .account-name {
          margin-bottom: 0;

          .ant-input {
            border: none;
            padding-left: 0 !important;
            padding-top: 0 !important;
          }
        }

        .inner-form {
          background: #ffffff;
          border-radius: 10px;
          padding: 16px 16px 8px;

          .ant-form-item-row {
            margin-bottom: 12px;
          }

          .ant-col {
            .ant-form-item:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .step-five {
      .paragraph {
          margin-bottom: 32px;
      }

      .ukg-id {
        .anticon-info-circle {
          margin-left: 7px;
          color: #505560;
          width: 14px;
          height: 14px;
        }
        .ant-select {
          width: 456px;
    background: #FFFFFF;
    border: 1px solid #E4E5E7;
    border-radius: 8px;
    margin-top: 7px;
    margin-bottom: 24px;
        }
        .inner-form {
          .ant-col {
            display: flex;
          }
        }
      }
    }
  }

  .steps-action {
    bottom: 24px;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 30%;

    .sidebar-user-btn {
      padding: 8px 16px;
      border-radius: 44px;
      width: 94%;
    }
    .white {
      background: #ffffff;
      color: black;
      border: 1px solid #e4e5e7 !important;
    }
    .black {
      background: #16181d;
      color: #fff;
    }
  }

.user-delete-popup {
  .ant-typography {
    font-size: 16px;
  }
  .row-button {
    .ant-btn {
      padding: 8px 16px;
      border-radius: 44px;
      width: 170px;
      height: 36px;
    }
    .delete {
      background: #e04369;
      color: #ffffff;
    }
    .cancel {
      background: #ffffff;
      border: 1px solid #e4e5e7 !important;
      color: #16181d;
    }
  }
}

.user-details-drawer {
  .ant-drawer-content-wrapper {
    width: 520px !important;
  }
  .user-detail {
    display: flex;
    flex-direction: column;

    .header {
      padding: 24px 32px;
      background: #f7f8f9;
      position: absolute;
      width: 520px;
      left: 0;
      display: inline-grid;
      justify-content: center;
      position: relative;
      margin-left: -32px;

      .profile-img {
        border-radius: 50%;
        width: 80px;
        height: 80px;
      }

      .align-content,
      .ant-image {
        display: flex;
        justify-content: center;
      }
      .location-user {
        background: #ffffff;
        border-radius: 24px;
        padding: 2px 8px;
        margin: 0 4px;
      }
      .padding-icon {
        background: #ffffff;
        border-radius: 24px;
        padding: 3px;
        margin: 0 4px;
      }
      .more-option {
        border: 1px solid #e4e5e7;
        border-radius: 44px;
        background: #ffffff;
        padding: 8px;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 24px;
        right: 32px;
        position: absolute;
      }

      .ant-rate {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;

        .ant-rate-star
        {
          margin-right: 2px;
        }
        .ant-rate-star-full {
          color: green;
        }
      }


      .anticon-star {
        background: white;
        padding: 2.571px;
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
    .content {
      position: relative;
      padding-top: 32px;
    }
  }
}

.option-div {
  justify-content: center;
  display: flex;

  img {
    margin-right: 8px !important;
  }
}

.detail-box {
  border: 1px solid #e4e5e7;
  border-radius: 8px;
  background: #ffffff;
  padding: 16px;
  margin-bottom: 12px;
  width: 456px;
  display: flex;
  align-items: center;

  .icon {
    background: #f2eee8;
    border-radius: 24px;
    width: 36px;
    height: 36px;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-right: 14px;
  }

  h2 {
    font-size: 16px;
  }
  .paragraph {
    font-size: 12px;
    font-weight: 400;
  }
}

.noborder-btn {
  background: none;
  padding-left: 0;

  span {
    color: #0B9F57;
  }
}

.user_filters {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
  align-items: flex-start;

  .role_multi {
    align-items:flex-start;
    align-content:flex-start;
    width: calc(100% - 34px);
  }

  .role_dropdown {
      border: 1px solid #E4E5E7;
      border-radius: 8px;
      height: auto;
      padding: 3px 6px 3px 10px;
      display: flex;
      align-items: flex-start;
      color: #505560;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      width: 255px;
      margin-right: 8px;
      margin-bottom: 8px;
      position: relative;

      .ant-select-selector {
        width: 130px;
        overflow: hidden;
      }

      &.type_dropdown {
        width: 232px;

        .ant-select-selector {
          width: 130px;
          overflow: hidden;
        }
      }

      &.location_dropdown {
        .ant-select-selector {
          width: 182px;
          overflow: hidden;
        }

        .ant-select-arrow {
          right: 8px;
        }
      }

      &.department_dropdown {
        .ant-select-selector {
          width: 140px;
          overflow: hidden;
        }
      }

      &.status_dropdown {
        width: 192px;

        .ant-select-selector {
          width: 110px;
          overflow: hidden;
        }
      }

      &.select_role {
        width: 235px;
        .ant-select-selector {
          width: 165px;
          overflow: hidden;
        }
      }

      &.pms_dropdown {
        width: 232px;

        .ant-select-selector {
          width: 160px;
          overflow: hidden;
        }
      }

      &.company_dropdown{
        width: 332px;

        .ant-select-selector {
          width: 145px;
          overflow: hidden;
        }
      }

      &.group_dropdown {
        width: 332px;

        .ant-select-selector {
          width: 215px;
          overflow: hidden;
        }
      }

      .label_dropdown {
        white-space: nowrap;
        color: #505560;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-top: 4px;
      }

      .ant-select-multiple {
        .anticon {
          width: auto;
          height: auto;
          padding: 0;
        }

        .ant-select-selection-placeholder {
          left: 6px;
        }

        .ant-select-selector {
          .ant-select-selection-overflow {
            flex-wrap: nowrap;
          }
        }
      }





      .ant-select-arrow {
          right: -20px;
          top: 14px;
      }

      .shift_tag {
          display: flex;
          background: #F2FCF7;
          border-radius: 3px;
          color: #0B9F57;
          white-space: nowrap;
          text-transform: capitalize;
          padding: 1px 5px;
          font-weight: 500;
          position: absolute;
          left: 107px;
          font-size: 12px;
      }
  }
  .filter_dropdown {
      .ant-select-selector {
          height: auto;
          border: 0;
          padding: 0;

          .ant-select-selection-search-input {
              height: 20px;
          }
          .ant-select-selection-placeholder,
          .ant-select-selection-item {
              line-height: 20px;
              font-weight: 500;
              color: #16181D;
              font-size: 14px;
          }
      }
  }
  .checklist_dropdown {
      .ant-select-selector {
          .ant-select-selection-item {
              padding-top: 1px;
              color: #0B9F57;
              font-size: 12px;
          }
      }
  }
}

.user_image_info {
  .edge_card {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .ant-avatar {
        margin-bottom: 16px;
      }

      h4 {
        &.ant-typography {
          color: #16181D;
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 12px;
        }
      }
    }
  }

  .role_type_user {
    display: flex;
    align-items: center;

    .role_label {
      background: #FFFFFF;
      border-radius: 24px;
      padding: 2px 8px;
      color: #16181D;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

    .phone_icon,
    .mail_icon {
      margin-left: 8px;

      a {
        width: 20px;
        height: 20px;
        background: #FFFFFF !important;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-image-img {
          display: block;
        }
      }
    }
  }
  .edit_filter {
    position: absolute;
    top: 24px;
    right: 32px;
  }

  .location_card {
    h3 {
      color: #16181D;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 12px;
    }

    .ant-card {
      border: 1px solid #E4E5E7;
      border-radius: 8px;
      margin-bottom: 12px;

      .ant-card-body {
        padding: 16px;
      }

      .ant-card-meta {
        margin: 0;

        .ant-card-meta-avatar {
          width: 40px;
          height: 40px;
          padding: 2px;
        }
        .ant-card-meta-detail {
          margin-left: 12px;

          .ant-card-meta-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #16181D;
            margin-bottom: 8px;
          }

          .ant-card-meta-description {
            .meta_desc {
              display: flex;
              align-items: center;

              h5 {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #16181D;
                margin: 0 8px 0 0;
              }

              .role_label {
                color: #16181D;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
              }

              span {
                color: #9CA0AA;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                margin: 0 4px;
              }
            }

          }
        }
      }
    }
  }

  button {
    &.edit_location_btn {
      padding: 6px;
      display: flex;
      align-items: center;
      margin-top: 4px;
      margin-bottom: 24px;

      span {
        color: #0B9F57;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        margin-left: 6px;
      }
    }
  }

  .ukg_menu {
    position: absolute;
    top: 24px;
    right: 22px;
  }

  .edit_ukg {
    &.ant-card {
      border: 0;
      margin-top: 12px;
      background: #F7F8F9;
      border-radius: 10px;

      h5 {
        color: #16181D;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 4px;
      }

      .ant-input {
        border: 1px solid #E4E5E7;
        border-radius: 8px;
        background: #FFFFFF;
        padding: 8px 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #16181D;
      }

      .edit_ukg_btn {
        margin-top: 16px;
      }
    }
  }
}

.steps-content {
  .heading {
    padding-top: 24px;

    h2 {
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 22px;
      line-height: 24px;
      color: #16181D;
    }
  }
  .upload_user_image {
    padding: 16px 0 24px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-upload {
      &.ant-upload-drag {
        border: 1px solid #E4E5E7;
        border-radius: 100px !important;
        background: #FFFFFF !important;
        width: 120px;
        height: 120px;
        overflow: hidden;
      }

      .ant-upload-btn {
        padding: 0;
      }
      .ant-upload-drag-image {
        width: 120px;
        height: 120px;

        .ant-image {
          width: 100%;
          height: 100%;

          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  .ant-input {
    border: 1px solid #e4e5e7;
    border-radius: 8px;
  }
  .ant-form-item-row {
    flex-direction: row;
  }
  .ant-form-item input.ant-input {
    padding: 8px 0 8px 12px;
  }
}

.step-one {
  .checkbox-credentials {
    .ant-checkbox-wrapper {
      display: flex;

      .ant-checkbox {
        width: 19.2px;
        height: 19.2px;
      }

      .paragraph {
        color: #686d79;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  .user-exist-info {
    background: #e6f9f0;
    border-radius: 10px;
    padding: 18px;
    display: flex;

    .anticon-exclamation-circle {
      margin-right: 14px;
    }
    .anticon svg {
      color: #0b9f57;
      opacity: 0.26;
      width: 20px;
      height: 20px;
    }
  }

  .col-field {
    margin-bottom: 24px;
  }
  .email-field {
    .ant-form-item {
      margin-bottom: 8px;
    }
    .left_icon .ant-form-item-label label {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #16181d;
    }
  }
}

.step-two {
  padding-bottom: 20px;
  .remove_locations {
    width: 36px;
    height: 36px;
    border-radius: 44px;
    padding: 8px;
    border: 1px solid #E4E5E7 !important;
    margin-top: -12px;
    display: block;

    &.index_1 {
      margin-top: 12px;
    }

    img {
      width: 100%;
    }
  }

  .add-location {
    display: flex;
    width: 425px;
    align-items: end;

  .location-dropdown {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
    max-width: 202px;
}

    span {
      margin-left: 8px;
    }

    .ant-select {
      background: #FFFFFF;
      border: 1px solid #E4E5E7;
      border-radius: 8px;
      width: 202px;
      height: 36px;
    }
  }
}

.step-three {
  .schedule-system {
    background: #ffffff;
    border: 1px solid #e4e5e7;
    border-radius: 16px;
    padding: 16px;
    display: flex;
    margin-bottom: 16px;

    .icon {
      margin-right: 16px;
    }

    .header {
      font-size: 18px;
      font-weight: 400;
      color: #16181d;
      padding-top: 0 !important;
      margin-right: 16px;
      width: 264px;
    }
    .paragraph {
      font-size: 14px;
      color: #505560;
      font-weight: 400;
    }
    .select-btn {
      border-radius: 44px;
      padding: 8px 16px;
      width: 80px;
      height: 32px;
      font-size: 12px;
      font-weight: 500;
    }
    .selected {
      color: white;
      background: #0b9f57;
    }
    .notSelected {
      color: black;
      background: #ffffff;
      border: 1px solid #e4e5e7 !important;
    }
  }
}

.top_drawer_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 32px;
  border-bottom: 1px solid #E4E5E7;
  margin-left: -32px;
  margin-right: -32px;

  .drawer_label {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #16181D;
  }
}

.table_name_wrapper {
  display: flex;
  align-items: center;
  width: 200px;

  @media (max-width: 1800px) {
    width: 160px;
  }

  .user_table_name {
    white-space: nowrap;
    width: calc(100% - 38px);
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ant-avatar {
    margin-right: 10px;

    @media (max-width: 1400px) {
      margin-right: 4px;
    }
  }

  @media (max-width: 1400px) {
    max-width: 110px;
  }
}

.button_style {
  width: 76px;
  height: 20px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  @media (max-width: 1400px) {
    width: 70px;
  }

  &.c-success {
    background: #0B9F57;
  }

  &.c-error {
    background: #E04369;
  }

  &.c-pending {
    background: #9CA0AA;
  }
}

.only_title_meta {
  .ant-card-meta-detail {
    display: flex;
    align-items: center;

    .ant-card-meta-title {
      margin-bottom: 0 !important;
    }
  }
}

.location_table_wrapper {
  display: flex;
  align-items: center;
  width: 170px;

  @media (max-width: 1800px) {
    width: 155px;
  }

  @media (max-width: 1400px) {
    width: 110px;
  }

  .label {
    max-width: calc(100% - 34px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tooltip_location {
    margin-left: 8px;
    background: #F2FCF7;
    border: 1px solid #E6F9F0;
    border-radius: 4px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0B9F57;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    @media (max-width: 1400px) {
      width: 24px;
      height: 24px;
      font-size: 11px;
    }
  }
}

.email_user_list {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
}
.role_color {
  border-radius: 1px;
  width: 7px;
  height: 7px;
  margin-right: 4px;
}

.full_spin_height {
  overflow-x: hidden;

  .ant-spin-container::after {
    margin-left: -32px;
    margin-right: -32px;
    width: calc(100% + 64px);
    height: calc(100vh - 50px);
  }
}

.new_form.ant-form-vertical {
  .location_fields .ant-form-item {
    margin-bottom: 10px;
  }
}

.location_popup {
  width: 255px;
  left: -68px !important;
}

.category_popup {
  width: 255px;
  left: -68px !important;
}

.usertype_popup {
  width: 232px;
  left: -75px !important;
}

.statuses_popup {
  width: 192px;
  left: -54px !important;
}

.role_popup {
  width: 232px;
  left: -38px !important;
}

.department_popup {
  width: 255px;
  left: -88px !important;
}

.hotel_popup {
  width: 332px;
  left: -88px !important;
}

.management_popup {
  width: 332px;
  left: -158px !important;
}

.pms_popup {
  width: 232px;
  left: -44px !important;
}

.checklist_role_popup {
  width: 250px;
  left: -42px !important;
}

.checklist_shift_popup {
  width: 250px;
  left: -42px !important;
}

.report_location_popup {
  width: 256px;
  left: -101px !important;
}
