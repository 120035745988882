@import './variables';

.sign_up_page {
  width: 100%;
  display: flex;
  height: 100vh;
  justify-content: center;
  overflow: hidden;

  .sign_up_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: auto;
    padding: 40px;
    width: 100%;
    background-color: #fff;

    @media (max-width: 1300px) {
      padding: 25px 40px;
    }

    .sign_up_center {
      max-width: 460px;
      max-height: 100%;
    }
  }

  .login_logo {
    margin-bottom: 48px;

    @media (max-width: 1400px) {
      margin-bottom: 30px;
    }

    @media (max-width: 1200px) {
      margin-bottom: 18px;
    }
  }

  .welcome_text {
    color: #505560;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }

  .sign_up_form {
    margin-top: 40px;

    .email_field {
      .ant-form-item {
        margin-bottom: 32px;

        @media (max-width: 1400px) {
          margin-bottom: 24px;
        }

        @media (max-width: 1200px) {
          margin-bottom: 20px;
        }
      }
    }

    .ant-form-item {
      @media (max-width: 1200px) {
        margin-bottom: 20px;
      }

      .formControl {
        border: 1px solid #E4E5E7;
        border-radius: 8px;
        padding: 8px 12px;
        height: 36px;

        &.ant-input-password {
          input {
            background: transparent;

            &::-webkit-autofill,
            &::-webkit-autofill:focus,
            &::-webkit-autofill:hover,
            &::-webkit-autofill {
              box-shadow: inset 0 0 0 100px #fff;
              -webkit-box-shadow: 0 0 0 100px #fff inset;
            }
          }
        }
      }
    }

    .ant-form-item-label {
      justify-content: flex-start;
    }

    .sign_up_btn {
      width: 100%;
      margin-top: 40px;
      background-color: $blue;
      border-radius: 44px;
      padding: 8px 16px;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      height: 36px;
      margin-bottom: 20px;

      @media (max-width: 1400px) {
        margin-top: 20px;
      }

      @media (max-width: 1200px) {
        margin-top: 14px;
      }

      &:hover {
        background-color: #264DC3;
      }
    }
  }

  .syncing {
    color: #000000;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-top: 200px;
  }
}
