.section_header {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 64px;
    box-shadow: 0px 0px 6px rgba(19, 20, 20, 0.16);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    .heading {
        display: flex;
        align-items: center;
        padding-left: 16px;

        .anticon {
            svg {
                display: block;
            }
        }

        .btn_title {
            font-weight: 700;
            font-size: 22px;
            line-height: 31px;
            margin-left: 10px;
        }

        .header_heading {
            font-size: 22px;
            line-height: 32px;
            color: #1B1C1D;
            margin-right: 16px;
            font-weight: 700;
        }
    }

    .section_filter {
        display: flex;
        padding-right: 16px;
    }

    .filter_box {
        margin-left: 12px;
    }

    .date_filter {
        height: 34px;
        width: 154px;
        background: #F0F0F0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 6px 7px;

        .label {
            font-size: 14px;
            color: #7F8385;
            margin-right: 8px;
        }

        .ant-picker {
            background: transparent;
            border: 0;
            padding: 0;
            width: 95px;
            color: #1B1C1D;
        }

        .ant-picker-clear {
            background: #F0F0F0;
        }
    }

    .filter_dropdown {
        width: 192px;

        .ant-select {
            width: 100%;
        }
    }

    .clear_filter {
        margin-left: 12px;
    }
}

.ant-select-dropdown {
    border-radius: 12px;
    padding: 12px 0;

    .rc-virtual-list-holder-inner>div:last-child {
        padding-bottom: 10px;
    }

    .ant-select-item-option {
        padding-left: 12px;
        text-transform: capitalize;
        
        .ant-avatar {
            margin-right: 10px;
        }
    }
}


.deactivatedHeader {
    background-color: #9CA0AA;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 244px;
    width: calc(100% - 244px);
    z-index: 999;

    .info_text {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .activate_btn {
        padding: 8px 8px 8px 4px;
        background-color: transparent;

        img {
            margin-right: 8px;
        }

        span {
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
        }
    }
}