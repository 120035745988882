@import './general/variables';

.project_section {
  width: 100%;
  background-color: #f5f5f5;
  min-height: 100vh;
  padding-top: 64px;

  .ant-card-body {
    min-height: auto;
  }
}

.new_project {
  .ant-card {
    margin: 16px;
  }
  .ant-card-body {
    padding: 0;
  }

  .add_form {
    > .ant-row {
      padding: 0 24px 24px;
    }
  }
}

.project_detail {
  .ant-card-body {
    padding: 0;
  }

  .top_detail_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid #dbdddf;
  }

  .project_detail_content {
    padding: 32px 24px;
  }

  .chip_group {
    display: flex;
    align-items: center;

    .ant-tag {
      color: #8578d7;
      padding: 5px 15px;
      background: rgba(133, 120, 215, 0.14);
      border-radius: 50px;
      border: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      margin-left: 8px;
      margin-right: 0;
      height: auto;
      width: auto;

      &.purple_tag {
        background: #8578d7;
        color: #fff;
      }
    }
  }

  .project_box {
    .ant-card-meta-detail {
      .ant-card-meta-title {
        color: #7f8385;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        margin: 0;
      }
      .ant-card-meta-description {
        color: #1b1c1d;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin: 0;
      }
    }
    .project_select_prefix {
    }
  }
}


.project_title {
  margin-left: -30px !important;
  inline-size: 110% !important;
  padding-left: 40px !important;
}

.project_card {
  padding: 16px;
  width: 100%;

  .ant-collapse-content {
    > .ant-collapse-content-box {
      padding: 0 !important;
    }
  }

  .ant-tag {
    &.table_tag {
      background-color: #b9bbbd;
      color: #fff;
      padding: 6px 0;
      border-radius: 50px;
      line-height: 1;
      margin: 0;
      width: 105px;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      border: 0;
      box-shadow: none;
    }

    &.inProgress,
    &.in_progress {
      background-color: #5e95e7;
    }
    &.notCompleted,
    &.not_completed {
      background-color: #b9bbbd;
    }
    &.completed {
      background-color: $blue;
    }
  }
}

.project_filer_card {
  width: 100%;
  .ant-collapse-content {
    > .ant-collapse-content-box {
      padding: 0 !important;
    }
  }

  .ant-tag {
    &.table_tag {
      background-color: #b9bbbd;
      color: #fff;
      padding: 6px 0;
      border-radius: 50px;
      line-height: 1;
      margin: 0;
      width: 105px;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      border: 0;
      box-shadow: none;
    }

    &.inProgress,
    &.in_progress {
      background-color: #5e95e7;
    }
    &.notCompleted,
    &.not_completed {
      background-color: #b9bbbd;
    }
    &.completed {
      background-color: $blue;
    }
  }
}


.ant-radio-circle {
  border-radius: 0 !important;
  border: 0 !important;
  .ant-radio {
    .ant-radio-input:hover {
      background-color: #392;
    }
    .ant-radio-inner {
      border-color: #392 !important;
      border-style: none !important;
      &::after {
        background-color: #392 !important;
      }
    }
    &:first-child {
      border-radius: 50% !important;
    }
  }
  .ant-radio-checked {
    border-radius: 0 !important;
  }
}


.custom_margin {
    margin-left: -20px !important;
    padding-bottom: 30px !important;
    .ant-col {
        padding-left: -10px !important;
    }
}

.project_box {
    .ant-image{
        margin-top: -40px;
    }
    textarea{
        overflow: hidden !important;
        .ant-input{
            height: 150px !important;
        }
    }
}


.tasks_card {
  .ant-progress-show-info{
    .ant-progress-outer {
      padding-right: calc(0em + 0px);
      margin-right: 0;
      .ant-progress-inner{
        position: static;
        .ant-progress-bg{
          background: $blue;
          border-radius: 54px;
          height: 18px !important;
          color: white;
          position: static;
        }
      }
    }
    .ant-progress-text{
      text-align: center;
      width: 100%;
      margin: 0;
      position: absolute;
      z-index: 999;
      top: 0;
      left: 0;
      line-height: 24px;
    }
  }
}


.task_form {
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $blue;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after{
    transform: rotate(0deg) scale(0) translate(0%, 0%) !important;
  }

  .ant-checkbox-checked::after{
    width: 42px !important;
    height: 42px !important;
    border: 1px solid $blue;
    border-radius: 50%;
  }

  .ant-checkbox {
    height: 42px;
    width: 42px;
  }
}

.ant-message-success .anticon {
display: none;
}
.ant-message-notice-content {
  background: #53595C 95%;
  border-radius: 10px;
  color: white;
}

.ant-message {
  bottom: 20px;
  right: 20px;
  top: auto;
  left: auto;
  width: auto;
}

.add_form {
  &.form_project {
    > .ant-row {
      padding-bottom: 32px !important;
    }
    .ant-form-item {
      padding-left: 34px !important;
      padding-right: 0px;
    }

    .select_prefix,
    .ant-input-prefix {
      left: -34px;
    }
  }

  .custom_project_name{
    width: 100%;
    padding: 25px 24px !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #B9BBBD;

    .formControl{
      border-bottom: none !important;
      padding: 0 !important;
    }

    .ant-form-item-control-input {
      min-height: 22px;
    }
  }
}


.project_top_select {
  display: flex;
    align-items: center;
    width: calc(100% + 48px);
    min-height: 73px;
    line-height: 1;
    border-bottom: 1px solid #DBDDDF;
    margin-left: -24px;
    margin-right: -24px;
    input {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #B9BBBD;
      letter-spacing: 0.003em;
    }
    .ant-form-item-explain-error {
      position: absolute;
      bottom: -50px;
      left: 0px;
    }
}
.project_textarea {
  textarea::placeholder {
  line-height: 3.5em;
  }
}

.extra_project_text {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7F8385;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 10% 0;
}

.edit_project_wrapper {
  padding: 16px;

  .ant-card {
    margin: 0 !important;
  }
}

.progress_white_text {
  .ant-progress-text {
    color: #fff !important;
  }
}

.project_task {
  padding: 0 !important;

  .ant-card {
    margin-top: 16px !important;
  }

  .section_header {
    position: relative;
    padding: 0;
    z-index: 0;
    top: auto;
    box-shadow: none;
    height: auto;
    margin-bottom: 21px;
    margin-left: 8px;
  }

  &.tasks_card .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-bottom: 18px;
  }
}
