@import './general/variables';

.upload_csv_floor span.ant-upload.ant-upload-btn:hover {
  border-color: #d5d7db;
}

.f_number_text_small {
  font-size: 12px !important;
}

.create_location_admin .ant-collapse-item-active .ant-collapse-expand-icon .ant-collapse-arrow svg {
  transform: rotate(180deg);
}

.stayover_due_wrapper .std_box .std_heading h6 {
  transform: rotate(180deg);
}
.upload_csv_floor .ant-upload.ant-upload-drag .drawer_error_note .info_card {
  overflow: initial;
}

.ant-space.ant-space-horizontal.ant-space-align-start {
  align-items: center;
}
.singleCheckbox .ant-checkbox-inner {
  border: 2px solid rgba(156, 160, 170, 1);
}

ul.ant-pagination.ant-table-pagination {
  li.ant-pagination-prev button.ant-pagination-item-link,
  li.ant-pagination-item,
  li.ant-pagination-next button.ant-pagination-item-link {
    border: none;
  }
  .ant-pagination-item:hover,
  .ant-pagination-item-active {
    border-color: $blue;
    border: 1px solid $blue !important;
    border-radius: 6px;
  }
  .ant-pagination-options {
    .ant-select-selector {
      border-radius: 6px;
      border: 1px solid rgba(228, 229, 231, 1);
      padding: 6px 12px;
    }
    span.ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }
}
.dashboard_section .dashboard_card .section_header {
  margin: 0;
}
.loc_detail_form .room_box.f_r_box {
  .ant-checkbox .ant-checkbox-inner {
    background: transparent;
  }
  .show_on_hover {
    padding: 15px 0 0;
  }
}
.f_r_box {
  .f_edit_del_btn {
    button span:hover {
      border-radius: 44px;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.setting_inner_height {
  width: calc(100% + 5px);

  ::-webkit-scrollbar-thumb {
    border-width: 3px 2px;
  }
  ::-webkit-scrollbar {
    margin: 2px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}


.operaCloudProcess {
  position: fixed;
  background: white;
  right: 24px;
  bottom: 38px;
  width: 520px;
  border: 1px solid #e4e5e7;
  border-radius: 24px;
  z-index: 11;

  .content {
    overflow: hidden;
    overflow-y: scroll;
    height: 382px;
    width: 100%;
    padding: 20px 32px;
    margin: 2px 0;
  }
  .opera-header {
    height: 68px;
    width: 100%;
    padding: 0 16px 0 32px;
    border-bottom: 1px solid rgba(228, 229, 231, 1);
    display: flex;
    align-items: center;

    .opera-popup {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
}

.main_notes .notes_wrapper .user_detail_wrapper .notes_user_image {
  margin-top: 10px;
  img {
    width: -webkit-fill-available;
  }
}

.user-exist-info {
  background: #e6f9f0;
  border-radius: 10px;
  padding: 18px;
  display: flex;
  margin-bottom: 24px;

  .anticon-exclamation-circle {
    margin-right: 14px;

    svg {
      color: $blue;
      opacity: 0.26;
      width: 20px;
      height: 20px;
    }
  }
}
.upload_loc_image .ant-upload.ant-upload-drag .ant-upload-drag-image .ant-image img {
  border-radius: 16px;
}

button {
  text-transform: capitalize;
}

.user-content {
  span.ant-avatar.ant-avatar-circle {
    border: 1px solid #e4e5e7;
  }
  span.ant-avatar.ant-avatar-circle.ant-avatar-image {
    border: none;
  }
}

.create_location_admin {
  .loc_detail_form {
    .ant-select-selector {
      border: 1px solid #e4e5e7;
    }
  }
}

.deactivedContent {
  margin-top: 52px;

  .new_theme {
    .new_header {
      top: 52px;
    }
  }

  .section_header {
    top: 52px;
  }

  .dashboard_section {
    .section_header {
      top: 0;
    }

    .workshift_sidebar {
      top: 52px;
    }
  }
}

.change_image_hover {
  &:hover {
    background: #eee !important;
    opacity: 0.95;
    border-radius: 16px;

    .btn_hover_icon {
      position: absolute;
      top: 60px;
      display: flex;
      gap: 10px;

      .ant-btn {
        background-color: white;
        border-radius: 16px;

        &:hover {
          background-color: grey;
        }
      }
    }
    .btn_icon1 {
      left: 130px;
    }
    .btn_icon2 {
      left: 260px;
    }
    .ant-image {
      opacity: 0.7;
    }
  }

  .upload_loc_image .ant-upload.ant-upload-drag:hover {
    background-color: transparent !important;
  }
}

.clear_floor_btn {
  padding: 0 !important;
  border: 0 !important;
  margin-right: 30px;

  &:hover {
    border: 0 !important;
  }
}
.floor_div {
  display: flex;
  position: sticky;
  left: 0;

  .clear_floor_btn {
    padding: 0 !important;
    border: 0 !important;
  }
  .col-50 {
    width: 50%;
  }
  .ant-row {
    width: 100%;
  }
  .col-50:last-child {
    display: flex;
    justify-content: flex-end;
  }
}
.ant-switch-checked {
  background: rgba(11, 159, 87, 1);
}
