@import './variables';

.scheduling_select {
  margin-top: 40px;

  .scheduling_top_text {
    margin-bottom: 32px;
  }

  h3 {
    color: #1B1C1D;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 14px;
  }

  p {
    color: #16181D;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }
}

.schedule_wrapper {
  width: 708px;
  .ant-card {
    margin-bottom: 16px;
    cursor: initial !important;

    .ant-card-body {
      padding: 24px;
    }
  }

  .scheduling_inside {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-card-meta {
      margin: 0;
    }
  }

  .schedul_col {
    display: block;

    .ant-card-meta {
      margin: 0;
    }

    .opera_5_meta {
      display: flex;
      width: 100%;

      .opera5_partnumber {
        margin-left: auto;
        background-color: #F7F8F9;
        padding: 8px 12px 6px 12px;
        border-radius: 12px;
      }

      .part_text {
        color: #686D79;
        line-height: 16px;
        font-size: 12px;
        font-weight: 400;
      }

      .part_text_icon {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: #16181D;
          line-height: 20px;
          font-size: 14px;
          font-weight: 500;
        }

        img {
          margin-left: 8px;
        }
      }
    }
  }

  .connect_edit_btns {
    display: flex;
    align-items: center;

    .edit_btn {
      margin-left: 16px;
    }

    .ant-tag {
      border-radius: 24px;
      padding: 2px 8px;
      width: auto;
      height: 20px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin-right: 6px;

      &.pending {
        background-color: #392EC2;
      }

      &.waiting {
        background-color: #D95B33;
      }
    }
  }

  .ant-card-meta-detail {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 15px;
  }

  .ant-card-meta-title {
    color: #16181D;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 4px !important;
  }

  .ant-card-meta-description {
    color: #686D79;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

.connect_ukg {
  width: 504px;
  margin-left: 78px;
  margin-top: 24px;

  h4 {
    color: #505560;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .add_organization_acount {
    button {
      background: #F2FCF7;
      border-radius: 12px;
      width: 100%;
      margin-bottom: 24px;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .anticon + span {
        margin-left: 2px;
      }

      span {
        color: #0B9F57;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 20px;

        &.anticon {
          svg {
            display: block;
            path {
              fill: #0B9F57;
            }
          }
        }
      }
    }
  }

  .edit_delete {
    display: flex;
    align-items: center;
    position: absolute;
    top: 27px;
    right: -50px;

    button {
      padding: 4px;
    }
  }
}

.add_ukg {
  &.ant-drawer .ant-drawer-body {
    padding: 0 32px 85px;
  }

  .top_drawer_header {
    margin-bottom: 24px;
  }

  h3 {
    color: #16181D;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 0;
  }

  .ant-select-multiple {
    .anticon {
      width: auto;
      height: auto;
      padding: 0;
    }
  }
}

.non_schedule {
  margin: 32px 0;
  width: 708px;

  h4 {
    color: #16181D;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
  }

  .select_role_admin {
    display: flex;
    margin-bottom: 0;

    .alow_label {
      margin-left: 16px;

      p {
        color: #686D79;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 0;
        }

        b {
          color: #16181D;
          font-weight: 400;
        }
      }
    }
  }
}

.multi_drop_down {
  &.ant-form-item {
    .ant-select {
      .ant-select-selector {
        padding-right: 50px;
        overflow: hidden;

        .ant-select-selection-overflow {
          flex-wrap: nowrap;
          overflow: hidden;
        }

        .ant-select-selection-search-input {
          padding: 0 !important;
        }
      }
    }
  }
}

.drop_with_email {
  .detail_label {
    margin-left: 40px;
  }
}


.ant-input-password {
  &.formControl {
    height: 36px;
    input.ant-input {
      padding: 0 !important;
      height: auto !important;
    }
  }
}

.add_new_project {
  margin: 8px 8px -2px 8px;
  border-top: 1px solid #E4E5E7;
  padding-top: 8px;

  .ant-btn {
    display: flex;
    align-items: center;
    background: transparent;
    padding: 8px 8px 8px 4px;

    > .anticon {
      height: 22px;
      svg {
        path {
          fill: #16181D
        }
      }
      + span {
        color: #16181D;
        font-size: 14px !important;
        line-height: 20px;
        font-weight: 500;
      }
    }
  }
}

.common_areas {
  padding-top: 28px;

  p {
    color: #7F8385;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
  }

  .add_common_btn {
    margin-bottom: 23px;
    background-color: $blue;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }

  .common_area_btns {
    margin-top: 28px;

    button {
      font-size: 14px;
    }
  }

  .common_row {
    width: 350px;
  }
  .common_area_input {
    width: 300px;
    margin-bottom: 0;

    .formControl {
      height: 40px;
    }
  }

  .col_wrap {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.opera-popup {
  h3 {
    color: #16181D;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 24px;
  }

  p {
    color: #16181D;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 24px;
  }

  .ul_wrapper {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
    .ant-tag {
      padding: 2px 8px;
      border-radius: 3px;
      line-height: 16px;
      font-size: 12px;
      font-weight: 500;
      width: auto;
      height: 20px;
      margin-bottom: 8px;
      margin-left: 34px;
    }

    .number {
      color: #0B9F57;
      line-height: 24px;
      font-size: 16px;
      font-weight: 500;
      margin-right: 8px;
      width: 26px;
      text-align: center;
    }

    .procss_info {
      display: flex;

    }

    .process_text {
      width: calc(100% - 32px);
    }

    p {
      color: #16181D;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 6px;

      &.copy_text {
        padding: 12px;
        color: #392EC2;
        border: 1px solid #fff;
        cursor: pointer;

        &:hover {
          background-color: #FBFBFC;
          border: 1px solid #EAEBF5;
          border-radius: 12px;
        }
      }
    }

    ul {
      padding-left: 22px;
      color: #16181D;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 6px;
    }
  }


}

.orange_tag {
  background-color: #FCF2EF;
  color: #D95B33;
}
.blue_tag {
  background-color: #F0EFFB;
  color: #392EC2;
}

.stayover_due_wrapper {
  .std_box {
    position: relative;

    .std_heading {
      width: 35px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      h6 {
        margin-bottom: 0;
        writing-mode: vertical-lr;
        margin: 0;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #fff;
      }
    }

    .std_text {
      padding: 16px 16px 16px 55px;

      p {
        color: #16181D;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .stayover_box {
    background-color: #FCF2EF;
    border-radius: 12px 12px 0 0;

    .std_heading {
      background-color: #D95B33;
      border-radius: 12px 0 0 0;
    }
  }
  .due_box {
    background-color: #F3EDF9;
    border-radius: 0 0 12px 12px;

    .std_heading {
      background-color: #782EC2;
      border-radius: 0 0 0 12px;
    }
  }
}
