.rating-color {
  color: #0b9f57;

  .ant-rate-star-half .ant-rate-star-first, .ant-rate-star-full .ant-rate-star-second {
    background: #E6F9F0;

  }

  .ant-rate-star-first .anticon, .ant-rate-star-second .anticon {
    padding: 4px;
    width: 28px;
    height: 28px;

    svg {
        width: 20px;
        height: 20px;
    }
  }

  .ant-rate-star-zero .ant-rate-star-first .anticon,.ant-rate-star-zero .ant-rate-star-second .anticon, .ant-rate-star-half .ant-rate-star-second {
    background: #FFFFFF;
    border-radius: 4px;
  }

  .ant-rate-star:not(:last-child) {
    margin-right: 4px;
  }
  .ant-rate-star-first, .ant-rate-star-second {
    line-height: 0;
    border-radius: 4px;
}
 
}

.rating-btn {
  border-radius: 44px;
  width: 100%;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;

  &.delete {
    background: #e04369;
    color: #ffffff;
  }

  &.cancel {
    background: #ffffff;
    border: 1px solid #e4e5e7 !important;
    color: #16181d;
  }

  &.submit {
    background: #0B9F57;
    color: #ffffff;
  }
}

.delete-popup {
  .ant-row {
    .ant-col:nth-child(1) {
      .ant-typography {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #16181d;
      }
    }
    .ant-col:nth-child(3) {
      .ant-typography:nth-child(1) {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #16181d;
      }
      .ant-typography:nth-child(2) {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #16181d;
        margin-bottom: 12px;
      }
    }
    
  }
}



.rating-textarea {
  margin-bottom: 0;
  margin-top: 16px;
  border: 1px solid #C6C9CE !important;
  border-radius: 8px !important;
  background: #FFFFFF !important;
  padding: 10px !important;
  height: 64px !important;
}

.rating-textarea-edit {
    margin-bottom: 4px;
    margin-top: 8px;

    textarea {
      border: 1px solid #C6C9CE !important;
      border-radius: 8px !important;
      background: #FFFFFF !important;
      padding: 10px !important;
      height: 64px !important;
    }
}

.comment-btn {
  display: flex;
  align-items: center;
  padding: 0 8px;
  color: #16181D;
  background-color: transparent;
  font-size: 12px;
  margin-bottom: 2px;
    &:focus,  
    &:hover {
      background-color: transparent;
      color: #16181D;
    }
    .ant-image {
        margin-right: 2px;
    }
  }

  .rating-readonly {
    color: #0b9f57;

    .ant-rate-star-first .anticon svg, .ant-rate-star-second .anticon svg {
        width: 15px;
            height: 15px;
    }
    .ant-rate-star:not(:last-child) {
        margin-right: 3px;
    }
    .ant-rate-star-half .ant-rate-star-first, .ant-rate-star-full {
        background: #F2FCF7;
        border-radius: 3px;
    }

    .ant-rate-star-zero, .ant-rate-star-active .ant-rate-star-second {
        background: #FBFBFC;
        border-radius: 3px;
    }

    .ant-rate-star-first, .ant-rate-star-second {
        line-height: 0;
        padding: 3px;
    }
  }

.rating-popup {
    .ant-modal-body {
        padding: 10px 24px 24px !important;
    }
}

.inspect-btn {
  button {
    background: #16181D;
    padding: 8px 16px 8px 16px;
    width: 100%;
    border-radius: 44px;
    height: 36px;
    color: #fff;
    font-size: 14px;

    &:hover {
      background: #25282F;
      color: #fff;
    }
  }
}

.rating_sidebar_show {
  width: 200px;
  display: flex;
  justify-content: space-between;
}