@import './variables';

.ui_kit {
  padding: 20px;
  width: 800px;
  display: flex;
  flex-direction: column;

  .ant-form-item {
    margin-bottom: 48px !important;
  }

  .ant-select {
    width: 100%;
  }

  .ant-picker-suffix {
    display: none;
  }

  .ant-picker {
    border: 0;
    background: transparent;
    border-bottom: 1px solid #dbdddf;
    border-radius: 0;
    width: 100%;
    color: $blackColor;
    padding: 9px 0 8px;
  }
  .ant-picker-input > input::placeholder {
    color: $placeholder;
  }

  .ant-picker-clear {
    background: none !important;
  }

  textarea.ant-input {
    border: 0;
    background: transparent;
    border-bottom: 1px solid #dbdddf;
    border-radius: 0;
    width: 100%;
    color: $blackColor;
    padding-left: 0;
    resize: none;
  }
}

.ant-form {
  position: relative;
  z-index: 1;
}

.ant-picker-dropdown {
  .ant-btn-primary,
  .ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: $blue !important;
  }

  .ant-picker-ranges > li a,
  .ant-picker-today-btn {
    color: $blue !important;
  }
}

.select_wrapper {
  width: 500px;
}

.col-100 {
  width: 100%;
  margin-bottom: 25px;
}

.ant-form-item-explain-error {
  color: #f37066 !important;
  font-size: 12px;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #f37066 !important;
}

.ant-form-item {
  &.ant-form-item-has-error {
    .formControl {
      border-color: #f37066 !important;
    }
  }
  .formControl {
    border: 0;
    background: transparent;
    border-bottom: 1px solid #dbdddf;
    border-radius: 0;
    width: 100%;
    color: $blackColor;
    padding: 0;
    box-shadow: none !important;

    &:focus {
      box-shadow: none !important;
    }

    &:hover {
      border-color: #dbdddf;
    }
  }

  input {
    &.ant-input {
      padding: 9px 0 8px;
      &:focus {
        box-shadow: none !important;
      }
      &:hover {
        border-color: #dbdddf;
      }
    }
  }
}

.add_form {
  > .ant-form-item {
    padding: 0;
    margin-bottom: 24px !important;
  }
}
.btns {
  .ant-form-item-label > label::after {
    display: none;
  }
}

.ant-btn {
  padding: 15px 20px;
  background: rgba(185, 187, 189, 0.22);
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #7f8385;
  height: auto;
  border: 0 !important;
  box-shadow: none !important;
  text-shadow: none !important;

  &.btn-sm {
    padding: 9px 12px !important;
    font-size: 13px !important;
    width: 63px;
    height: 34px;
  }
  &.btn-md {
    padding: 11px 16px !important;
    font-size: 14px !important;
    width: 74px;
    height: 40px;
  }
  &.btn-lg {
    padding: 15px 20px !important;
    font-size: 17px !important;
  }
  &.disabled {
    background: $silverlight;
    color: #b9bbbd;
  }

  &.ant-btn-primary {
    background: $blue;
    color: #fff;
  }

  &.ant-btn-circle {
    border-radius: 8px !important;
    width: 40px !important;
    height: 40px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.ant-btn-link {
    background: transparent !important;
    padding: 2px;

    :hover {
      border-radius: 4px;
      color: black;
    }

    &.anticon-more {
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-content: space-around;
      flex-wrap: wrap;
      font-size: 17px !important;
    }

    .ant-btn > .anticon,
    .anticon svg {
      font-size: 18px !important;
    }
  }

  &.ant-btn-dangerous {
    background-color: $red !important;
    color: #fff !important;
  }

  &.red-btn {
    background: $red;
    color: #fff;

    &:hover {
      background: $red-hover;
    }
  }
  &.blue-btn {
    background: $blue;
    color: #fff;

    &:hover {
      background: $blue-hover;
    }
  }
  &.green-btn {
    background: $blue;
    color: #fff;

    &:focus,
    &:hover {
      background: $blue-hover;
      color: #fff;
    }
  }
  &.grey-btn {
    background: $grey;
    color: #7f8385;

    &:hover {
      background: $grey-hover;
      color: #53595c;
    }
  }
  > .anticon + span {
    font-size: x-large !important;
  }
  & .anticon {
    font-size: x-large !important;
  }

  &.ant-btn-text {
    padding: 0;
    background: transparent;
    color: #1b1c1d;
    &.md:hover {
      background: rgba(185, 187, 189, 0.22);
      border-radius: 6px;
    }
    &.sm:hover {
      background: rgba(185, 187, 189, 0.22);
      border-radius: 4px;
    }
  }
}

.ant-form-item-label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ant-card {
  border-radius: 12px;
  overflow: hidden;
}

.tasks_card {
  .ant-card {
    margin-bottom: 16px;
    border: 0;
  }
  .ant-card-body {
    padding: 0;
  }
  .ant-collapse {
    > .ant-collapse-item {
      > .ant-collapse-header {
        padding: 23px 24px 25px;
        display: inline-flex;
        flex-direction: row-reverse;
        align-items: center;

        .ant-collapse-arrow {
          margin: 0 0 0 10px;
          transition: 0.5s;
        }
      }

      .ant-collapse-header-text {
        color: $blackColor;
        letter-spacing: 0.003em;
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
      }

      &.ant-collapse-item-active {
        .ant-collapse-arrow {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.ant-table-container {
  table {
    > .ant-table-thead {
      > tr {
        th {
          background: transparent;
          color: #7f8385;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          padding: 4px 8px 13px;
          border-bottom: 1px solid #dbdddf;

          &:before {
            display: none;
          }

          &:first-child {
            padding-left: 24px;
          }

          &:last-child {
            text-align: center;
          }
        }
      }
    }
    > .ant-table-tbody {
      .isDue {
        background: rgba(243, 112, 102, 0.14);
        border: 1px solid #dbdddf;
        &:hover {
          background: rgba(243, 112, 102, 0.2) !important;
        }
        :nth-child(7) {
          color: #f37066;
        }
      }
      > tr {
        cursor: pointer;
        td {
          padding: 6px 8px 5px;
          color: $blackColor;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          border-bottom: 1px solid #dbdddf;

          @media (max-width: 1400px) {
            font-size: 12px;
          }

          &:first-child {
            padding-left: 24px;
          }
          &:last-child {
            padding-right: 24px;
          }
        }

        &:hover {
          background-color: rgba(185, 187, 189, 0.1) !important;

          td {
            background: transparent !important;
          }
        }

        &.ant-table-placeholder {
          td {
            padding: 35px 0;
            color: #7f8385;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            cursor: initial;
          }

          &:hover {
            background-color: #fff !important;
          }
        }

        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }
    }
    .ant-avatar {
      background: #fff;
      border: 1px solid #53595c;
      color: #53595c;
      font-weight: 400;
      font-size: 12px !important;

      .ant-avatar-string {
        font-size: 12px !important;
      }
    }

    .ant-avatar-group {
      display: flex;

      .ant-avatar {
        background-color: #fff;

        &:not(:first-child) {
          margin-left: -4px;
        }
      }
    }
  }
}

.table_menu {
  padding: 12px 0 !important;
  border-radius: 12px !important;
  overflow: hidden;

  li {
    padding: 0;

    button {
      &.ant-btn-link {
        min-width: 100px;
        padding: 7px 15px !important;
        color: $blackColor !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 21px;
        text-align: left;
      }
    }
  }
}


.white-modal {
  .ant-modal-content {
    border-radius: 16px;

    .ant-modal-body {
      padding-top: 10px;
      padding-left: 32px;
      padding-right: 32px;
      padding-bottom: 32px;
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: $blue !important;
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
    border-radius: 12px;

    .ant-picker-time-panel {
      padding-top: 12px;
    }
    .ant-picker-time-panel-column {
      > li.ant-picker-time-panel-cell-selected {
        .ant-picker-time-panel-cell-inner {
        }
      }
    }

    .ant-btn {
      width: 42px;
      height: 34px !important;
      padding: 9px;
      font-size: 13px !important;
      line-height: 1.2 !important;
      border-radius: 6px !important;
    }
    .ant-picker-now {
      padding-left: 6px;
    }
    .ant-picker-now-btn {
      font-size: 13px;
    }
  }
}

.ant-picker-header-view button:hover {
  color: $blue;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $silverlight !important;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: $silverlight !important;
}

.ant-select-item {
  color: #1b1c1d;
  font-weight: 400;
  font-size: 14px;
}

.ant-select-item-group {
  color: #5e95e7;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  padding: 8px 12px 6px;

  &:not(:first-child) {
    margin-top: 6px;
    border-top: 1px solid #dbdddf;
  }
}



.ant-select-multiple {
  .ant-select-selection-item {
    border-radius: 4px;
    border-color: #f5f5f5;
    padding-left: 7px;

    .ant-avatar {
      width: 20px !important;
      height: 20px !important;
      line-height: 20px !important;

      span {
        line-height: 20px !important;
      }
    }

    .option-label-item {
      display: flex;
      align-items: center;

      > span {
        color: #1b1c1d;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        &:last-child {
          margin-left: 6px;
        }
      }
    }
  }
  .anticon {
    border-radius: 10px;
    width: 15px;
    height: 15px;
    padding: 2px;

    &:hover {
      background: $close-hover;
    }
  }

  .ant-select-selection-search {
    -webkit-margin-start: 0;
    margin-inline-start: 0;
  }

  .ant-select-selector {
    .ant-select-selection-overflow {
      display: flex;
      flex: auto;
      flex-wrap: wrap;
      max-width: 100%;
    }
  }
}

.ant-select {
  .ant-select-selector {
    z-index: 9;
    box-shadow: none !important;
    cursor: pointer !important;
  }

  &.custom_dropdown {
    .ant-select-selector {
      &.ant-select-open {
        background-color: $silvermedium !important;
      }

      &:hover {
        background-color: $silvermedium !important;
      }
    }
  }
}

.ant-input {
  &:focus {
    box-shadow: none !important;
  }
}


.hint_text {
  font-size: 12px;
  line-height: 17px;
  color: #7f8385;
  margin-top: 3px;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-spin-nested-loading {
  width: 100%;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: $blue;
}

.close_popup {
  margin-right: -24px;
  .ant-image {
    :hover {
      background: rgba(185, 187, 189, 0.22);
      border-radius: 6px;
    }
  }
}

.ant-dropdown-menu {
  box-shadow: 0px 0px 16px rgba(27, 28, 29, 0.12), 0px 0px 8px rgba(27, 28, 29, 0.04);
  border-radius: 12px;
  padding: 12px 0px;

  .ant-dropdown-menu-item {
    padding: 0;

    .ant-image {
      display: none;
      margin-left: auto;
    }

    .circle {
      width: 8px;
      height: 8px;
      background: #b9bbbd;
      border-radius: 4px;
      margin-right: 8px;

      &.grey-circle {
        background: #b9bbbd;
      }

      &.blue-circle {
        background: #5e95e7;
      }

      &.green-circle {
        background: $blue;
      }
    }

    &.ant-dropdown-menu-item-selected {
      background: rgba(185, 187, 189, 0.14);

      .ant-image {
        display: inline-block;
      }
    }

    .ant-btn {
      border-radius: 0;
      background: transparent;
      padding: 5px 12px;
      display: flex;
      align-items: center;
      min-width: 100px;
      height: 32px;
      font-size: 14px;
      color: #1b1c1d;
      font-weight: 400;
      line-height: 21px;
    }
  }
}

.icon_hover {
  .header {
    margin: 5px 0;
    width: 46px;
    &.kabab {
      margin-right: 8px;
    }
  }
  .ant-btn.ant-btn-text,
  .ant-btn.ant-btn-link {
    :hover {
      background: rgba(185, 187, 189, 0.22) !important;
      border-radius: 8px;
    }
  }
  .anticon.anticon-more:hover {
    background: rgba(185, 187, 189, 0.22) !important;
    border-radius: 8px;
  }
}

.detail_label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #686D79;
  display: block;
  width: 100%;
  white-space: pre-wrap;
  text-transform: initial;
}

.ant-select-selection-item {
  .detail_label {
    display: none;
  }
}

.ant-form-item-label > label {
  color: #16181D;
}






.main_notes {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  overflow-y: hidden;

  &.room_notes {
    height: calc(100vh - 65px);
  }

  .main_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 17px 0px;
    background: #FBFBFC;
    height: calc(100% - 60px);
  }

  .scrollBar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    padding-bottom: 20px;
    overscroll-behavior: contain;
    padding: 0 32px 32px 32px;
  }

  .notes_wrapper {
    padding-top: 20px;

    .user_detail_wrapper {
      padding: 8px;
      background: #fff;
      border-radius: 12px;
      margin-top: 16px;

      .notes_user_text {
        color: #16181D;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        margin-top: 6px;
      }
    }

    .note_user_details {
      display: flex;
      align-items: center;

      .edit_message_btn {
        margin-left: auto;
        display: flex;
        align-items: center;

        .ant-btn.circle_btn {
          width: 32px;
          height: 32px;
          border: 0 !important;
        }
      }

      .ant-avatar {
        margin-right: 8px;

        &.initials {
          border: 1px solid #E4E5E7;
          background: #fff;
          color: #505560;
          font-weight: 500;
          font-size: 14px;
          line-height: 28px;

          .ant-avatar-string {
            line-height: 28px !important;
          }
        }
      }
    }

    .notes_date_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;


      &::after {
        content: '';
        width: 100%;
        height: 1px;
        background: #E4E5E7;
        position: absolute;
        left: 0;
        top: 8px;
      }
    }

    .notes_dates {
      padding: 0 16px;
      background-color: #FBFBFC;
      color: #16181D;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      position: relative;
      z-index: 2;
    }

    .note_name {
      margin-right: 4px;
      color: #16181D;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      display: inline;
    }

    .note_sub_name {
      margin-right: 4px;
      color: #16181D;
      font-size: 10px;
      line-height: 16px;
      font-weight: 400;
      opacity: 0.5;
      display: inline;
    }

    .system_logs {
      color: #16181D;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    }

    .note_time {
       color: #9CA0AA;
       font-size: 10px;
       font-weight: 500;
       line-height: 16px;
    }

    .note_sytem_wrapper {
      margin-top: 20px;
      display: flex;
      align-items: flex-start;

      .note_time {
        margin-left: auto;
        white-space: nowrap;
        padding-left: 16px;
      }
    }
  }
}

.report_loader {
  .anticon  {
    font-size: 16px;
    color: #fff;
    margin-left: 10px;
  }
}
